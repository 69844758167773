'use client';
import { cn } from '@/lib/utils';
import { motion } from 'framer-motion';
import { FC, useState } from 'react';
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar';
import { Button } from './ui/button';

type PCMemberProps = {
  title: string;
  firstName: string;
  lastName: string;
  affiliation: string;
  country: string;
  image?: string;
  homepage?: string;
};

const PCMember: FC<PCMemberProps> = ({
  firstName,
  lastName,
  affiliation,
  country,
  image,
  title,
  homepage
}) => {
  const content = (
    <div>
      <div className='flex flex-row md:flex-col items-center space-y-3 gap-4 md:gap-0'>
        <Avatar className='h-20 w-20'>
          <AvatarImage
            alt={`${title} ${firstName} ${lastName}`}
            src={image}
            className='object-cover'
          />
          <AvatarFallback className='bg-slate-300 dark:bg-slate-700'>
            {firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase()}
          </AvatarFallback>
        </Avatar>
        <div className='md:text-center space-y-1'>
          <div className='font-medium'>
            {title} {firstName} {lastName}
          </div>
          <div className='text-gray-400 dark:text-gray-500 text-sm'>
            {affiliation}, {country}
          </div>
        </div>
      </div>
    </div>
  );

  if (homepage) {
    return (
      <a href={homepage} target='_blank' rel='noopener noreferrer' className='cursor-pointer'>
        {content}
      </a>
    );
  }

  return content;
};

type PCMembersProps = {
  members: PCMemberProps[];
};

export const PCMembers: FC<PCMembersProps> = ({ members }) => {
  const [showAll, setShowAll] = useState(false);

  return (
    <div>
      <motion.div
        initial={{ maxHeight: '24rem' }}
        animate={{ maxHeight: showAll ? 'fit-content' : '24rem' }}
        transition={{ duration: 0.5 }}
        className={cn(
          'grid grid-cols-[repeat(auto-fill,minmax(280px,_1fr))] gap-4 w-full',
          showAll ? '' : 'overflow-hidden'
        )}>
        {members
          .sort((a, b) => a.lastName.localeCompare(b.lastName))
          .map((member) => (
            <PCMember
              key={`${member.firstName}_${member.lastName}`}
              title={
                member.title.includes('Prof') || member.title.includes('Dr') ? member.title : ''
              }
              firstName={member.firstName}
              lastName={member.lastName}
              affiliation={member.affiliation}
              country={member.country}
              homepage={member.homepage}
              image={member.image}
            />
          ))}
      </motion.div>
      <div className={cn('inset-x-0 flex justify-center -mt-9 relative', showAll ? 'mt-8' : '')}>
        <div
          className={cn(
            'absolute inset-x-0 bg-gradient-to-t from-background from-0% -top-32 bottom-1',
            showAll ? 'hidden' : ''
          )}
        />
        <Button
          variant='link'
          size='lg'
          className='w-full sm:w-auto relative bottom-0'
          onClick={() => setShowAll(!showAll)}>
          {showAll ? 'Show Less Members' : `Show All ${members.length} Members`}
        </Button>
      </div>
    </div>
  );
};
