'use client';
import { cn } from '@/lib/utils';
import { FC, useState } from 'react';
import { Button } from './ui/button';

type ShowMoreTextProps = {
  children: React.ReactNode;
  limit: number;
  className?: string;
};

const ShowMoreText: FC<ShowMoreTextProps> = ({ children, limit, className }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div>
      <div className={cn(className, `${!showMore ? `line-clamp-${limit}` : 'line-clamp-none'}`)}>
        {children}
        <div className='line-clamp-5' />
      </div>
      <Button variant='link' onClick={() => setShowMore(!showMore)} className='p-0'>
        {showMore ? 'Show less' : 'Show more'}
      </Button>
    </div>
  );
};

export default ShowMoreText;
