'use client';
import { motion, useAnimation, useInView } from 'framer-motion';
import { FC, useEffect, useRef } from 'react';

type RevealProps = {
  children: React.ReactNode;
  className?: string;
};

export const Reveal: FC<RevealProps> = ({ children, className }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start('visible');
    }
  }, [isInView, mainControls]);

  return (
    <motion.div
      ref={ref}
      className={className}
      initial='hidden'
      animate={mainControls}
      transition={{ duration: 0.5 }}
      variants={{
        hidden: { opacity: 0, y: 0 },
        visible: { opacity: 1, y: 0 }
      }}>
      {children}
    </motion.div>
  );
};
