import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["PCMembers"] */ "/app/src/components/pc-member.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/show-more.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Avatar","AvatarImage","AvatarFallback"] */ "/app/src/components/ui/avatar.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/ui/reveal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Vortex"] */ "/app/src/components/ui/vortex.tsx");
