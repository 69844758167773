import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export type RawPCMember = {
  'First Name': string;
  'Last Name': string;
  Title: string;
  Affiliation: string;
  Country: string;
  Email: string;
  Status: string;
  Homepage?: string;
  Image?: string;
};

export type PCMember = {
  title: string;
  firstName: string;
  lastName: string;
  affiliation: string;
  country: string;
  image?: string;
  homepage?: string;
};

export function convertPCMembersJSON(members: RawPCMember[]): PCMember[] {
  const res = members
    .filter((member) => member.Status === 'accepted')
    .map((member) => ({
      title: member.Title,
      firstName: member['First Name'],
      lastName: member['Last Name'],
      affiliation: member.Affiliation,
      country: member.Country,
      image: member.Image,
      homepage: member.Homepage
    }));
  return res;
}
